import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { GatsbyImage } from 'gatsby-plugin-image';

import { StructuredText, Image } from "react-datocms";

import './page.scss';

export const query = graphql`
query($id: String) {
    datoCmsPage(id: {eq: $id}) {
      id
      slug
      title
      content {
        value
        links
        blocks {
          __typename
          ... on DatoCmsImageBlock {
            id: originalId
            image {
              url
            }
          }
          ... on DatoCmsLinkBlock {
            id: originalId
            url
          }
        }
      }
    }
  }
  
`

function TestPage(props){
    
    console.log("test page")
    console.log(props.data)

 return(   
     <Layout>
    <Seo title={props.data.datoCmsPage.title} />
    <div className="content-page">
    
    <h1>{props.data.datoCmsPage.title}</h1>

    <p>below is content</p>
    <StructuredText data={props.data.datoCmsPage.content}
    renderBlock={({ record }) => {
      switch (record.__typename) {
        case 'DatoCmsImageBlock':
          return <picture><img src={record.image.url} /></picture>;
        default:
          return null;
      }
    }}
    />
    </div>
 
  </Layout>
 )
}

export default TestPage



// const ComponentName = ({ data }) => <pre>{JSON.stringify(data, null, 4)}</pre>


const renderBlock = ({record}) => {
  switch (record.__typename) {
    case ' DatoCmsImageBlock':
      return <img scr={record.image.url} />;
    default:
      return null;
  }
};